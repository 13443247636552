.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  opacity: 0;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(30deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 0;
    transform: rotateZ(30deg);
    z-index: 1;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10px;
}
